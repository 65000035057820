.body {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

.map-container {
    height: 100dvh;
}

.spinner-container {
    position: absolute; 
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

#custom-switch{
    background-color: white;
  }

#custom-switch:checked{
    background-color: purple;
}