.orar-body {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
}

.orar-table {
    max-height: 60vh;
    overflow: auto;
}

thead th {
 position: sticky;
 top: 0px;
 z-index: 1;
}

td, th {
  text-align: center;
}

.orar-buttons {
  width: 100vw;
}

.orar-tabs > a {
  color: purple;
}
.nav-tabs .nav-link {
  color: purple;
}