.marker {
  display: grid;
  align-items: center;
  font-size: 14px;
  width: max-content;
  height: 30px;
  color: white;
  background-color: purple;
  border-radius: 5px;
  padding: 5px;
  z-index: 0;
}

.marker-linie-favorita {
  display: grid;
  align-items: center;
  font-size: 14px;
  width: max-content;
  height: 30px;
  color: white;
  background-color: indigo;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
}

.marker-linie-urmarita {
  display: grid;
  align-items: center;
  font-size: 14px;
  width: max-content;
  height: 30px;
  color: white;
  background-color: #DC3545 !important;
  border-radius: 5px;
  padding: 5px;
  z-index: 2;
}

.marker-invisible {
  display: none;
}

.marker-visible {
  display: initial;
}

.zindex100{
  z-index: 100 !important;
}