.orare-body-container {
    width: 98vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.orare-content-container {
    width: 80vw;
    min-width: 380px;
    padding: 20px;
    flex-direction: column;
    display: flex;
}

.orare-search-button {
    background-color: purple;
}

.orare-search-button:hover {
    background-color: indigo;   
}

.orare-cell {
    padding-top: -10px;
    padding-bottom: -10px;
}

.orare-cell:hover {
    cursor: pointer;
}