.stiri-content {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.stiri-content::-webkit-scrollbar { 
    display: none;
}

.stiri-card {
    width: 18rem;
    height: 10rem;
}

a {
    color: purple;
}